* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  color: #8b9c9e;
  background: radial-gradient(at 0%, #06313e, #084355);
  font-family: Inconsolata, monospace;
  font-weight: 400;
}

a {
  color: #3892d4;
}

.world {
  padding-bottom: 2em;
}

.world > h1 {
  text-align: center;
  white-space: nowrap;
  font-variant: small-caps;
  color: #062934;
  margin: .3em auto .5em;
  font-size: 13vw;
  font-weight: 900;
  position: relative;
}

@media (min-width: 800px) {
  .world > h1 {
    font-size: 10em;
  }
}

.world > h1:before, .world > h1:after {
  content: attr(title);
  color: #ffffff0a;
  filter: blur(3px);
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translateX(-50%);
}

.world > h1:after {
  filter: blur();
  top: 2px;
  left: calc(50% + 1px);
}

.dapps {
  text-align: center;
  max-width: 1100px;
  flex-flow: wrap;
  place-content: center;
  gap: 1em;
  margin: auto;
  display: flex;
}

.dapp {
  width: calc(100% - 1em);
  max-width: 90%;
  background-color: #0003;
  flex: 1 0 calc(100% - 1em);
  padding: .7em;
  text-decoration: none;
  transition: background-color .15s ease-out;
  box-shadow: inset 0 1px 3px -1px #0006, 0 1px 3px #ffffff0d;
}

@media (min-width: 600px) {
  .dapp {
    margin-bottom: 2em;
    padding: 2em 1em;
  }

  .dapp:not(.has-subprojects) {
    width: calc(50% - 1em);
    flex: 1 0 calc(50% - 1em);
  }

  .dapp:nth-last-of-type(-n+2) {
    margin-bottom: 0;
  }
}

@media (min-width: 800px) {
  .dapp {
    padding: 2em 3em;
  }
}

.dapp[href=""], .dapp:not([href]) {
  pointer-events: none;
}

.dapp:hover, .dapp:focus {
  background-color: #0000004d;
}

.dapp .name {
  margin-bottom: .4em;
  font-size: 2em;
}

@media (min-width: 1000px) {
  .dapp .name {
    font-size: 2.4em;
  }
}

.dapp .name .idea-phase {
  color: green;
  font-size: .6em;
}

.dapp .description {
  color: #8b9c9e;
  margin: 0 auto;
  font-size: 1.2em;
  line-height: 1.2;
}

@media (min-width: 1000px) {
  .dapp .description {
    font-size: 1.4em;
  }
}

.dapp.new {
  background-color: #00290033;
}

.dapp.new:hover, .dapp.new:focus {
  background-color: #001e0033;
}

.dapp .new {
  color: green;
  margin-bottom: .1em;
  font-size: 1.6em;
}

.dapp.has-progress {
  --progress-percent: calc(var(--progress) * 1%);
  padding-bottom: 2.5em;
  position: relative;
}

.dapp.has-progress:after, .dapp.has-progress:before {
  content: "Progress";
  background-color: #839800;
  background: linear-gradient(to right, #839800 var(--progress-percent), transparent var(--progress-percent));
  color: #0000;
  width: 100%;
  padding: .3em 0;
  font-size: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
}

footer {
  text-align: center;
  color: #839496;
  margin: 2em auto;
  font-size: 1.3em;
  font-weight: 300;
}

@media (min-width: 800px) {
  footer {
    margin: 4em auto 2em;
  }
}

footer .credits {
  flex-flow: wrap;
  place-content: center space-evenly;
  gap: 1em 3em;
  font-size: 1.1em;
  line-height: 1.2;
  display: flex;
}

footer .credits .by {
  width: 100%;
  margin-bottom: .5em;
}

footer .credits .by a {
  color: #839800;
  text-decoration: none;
  transition: color .15s ease-in;
}

footer .credits .by a:hover, footer .credits .by a:focus {
  color: #9db700;
}

/*# sourceMappingURL=index.5167a17c.css.map */
