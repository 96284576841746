@use 'sass:color';

@import 'breakpoint-sass';

$small: 600px;
$smallish: 800px;
$medium: 1000px;

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

$primary: #06313e;

body {
  font-family: "Inconsolata", monospace;

  color: #8b9c9e;

  font-weight: 400;

  background: radial-gradient(farthest-corner at 0%, $primary, lighten($primary, 5%));
}

a {
  color: #3892d4;
}

.world {

  padding-bottom: 2em;

  > h1 {
    position: relative;
    font-size: 13vw;
    margin: 0.3em auto 0.5em;
    text-align: center;
    white-space: nowrap;

    font-variant: small-caps;

    font-weight: 900;

    color: color.adjust($primary, $blackness: 4%);

    @include breakpoint($smallish) {
      font-size: 10em;
    }

    &:before, &:after {
      content: attr(title);
      color: rgba(white, .04);
      position: absolute;

      top: 1px;
      left: 50%;

      filter: blur(3px);

      transform: translateX(-50%);
    }

    &:after {
      top: 2px;
      left: calc(50% + 1px);
      filter: blur(0);
    }
  }

}

.dapps {
  display: flex;
  flex-flow: row wrap;

  text-align: center;

  justify-content: center;
  align-content: center;

  gap: 1em;

  max-width: 1100px;
  margin: auto;
}

.dapp {
  width: calc(100% - 1em);
  flex: 1 0 calc(100% - 1em);

  max-width: 90%;

  padding: 0.7em;

  text-decoration: none;

  transition: background-color 0.15s ease-out;
  background-color: rgba(black, .2);
  box-shadow:
    inset 0 1px 3px -1px rgba(black, .4),
    0 1px 3px rgba(white, .05);

  @include breakpoint($small) {

    &:not(.has-subprojects) {
      width: calc(50% - 1em);
      flex: 1 0 calc(50% - 1em);
    }

    padding: 2em 1em;
    margin-bottom: 2em;

    &:nth-last-of-type(-n+2) {
      margin-bottom: 0;
    }
  }

  @include breakpoint($smallish) {
    padding: 2em 3em;
  }

  &[href=''], &:not([href]) {
    pointer-events: none;
  }

  &:hover, &:focus {
    background-color: rgba(black, .3);
  }


  .name {
    font-size: 2em;
    margin-bottom: 0.4em;

    @include breakpoint($medium) {
      font-size: 2.4em;
    }

    .idea-phase {
      color: green;

      font-size: 0.6em;
    }
  }

  .description {
    color: #8b9c9e;
    font-size: 1.2em;
    line-height: 1.2;

    margin: 0 auto;

    @include breakpoint($medium) {
      font-size: 1.4em;
    }
  }

  &.new {
    background-color: #00290033;

    &:hover, &:focus {
      background-color: #001e0033;
    }
  }

  .new {
    color: green;
    margin-bottom: 0.1em;
    font-size: 1.6em;
  }

  &.has-progress {
    position: relative;
    --progress-percent: calc(var(--progress) * 1%);

    padding-bottom: 2.5em;

    &:after, &:before {
      content: 'Progress';

      position: absolute;
      bottom: 0;
      left: 0;

      font-size: 1em;

      padding: 0.3em 0;

      background-color: #839800;
      background: linear-gradient(to right, #839800 var(--progress-percent), transparent var(--progress-percent));

      color: transparent;

      width: 100%;
    }
  }
}

footer {

  margin: 2em auto;
  text-align: center;

  font-weight: 300;

  font-size: 1.3em;

  color: #839496;

  @include breakpoint($smallish) {
    margin: 4em auto 2em;
  }

  .credits {
    font-size: 1.1em;
    line-height: 1.2;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-content: center;
    gap: 1em 3em;

    .by {
      width: 100%;
      margin-bottom: 0.5em;

      a {
        color: #839800;
        transition: color .15s ease-in;
        text-decoration: none;

        &:hover, &:focus {
          color: color.adjust(#839800, $lightness: 6%);
        }
      }
    }
  }
}
